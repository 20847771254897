<template>
  <div>
    <el-form :inline="true">
      <!-- 添加/搜索区域 -->
      <el-form-item>
        <el-button @click="addinfo">{{ $t('xinzeng') }}</el-button>
      </el-form-item>
      <!-- 列表区域 -->
      <el-form-item>
        <el-input
          v-model="queryinfo.query"
          :clearable="true"
          @clear="getlist"
          style="width:240px"
        >
          <el-button
            slot="append"
            icon="el-icon-search"
            @click="getlist"
          ></el-button>
        </el-input>
      </el-form-item>
    </el-form>
    <el-table v-loading="loading" :data="infolist" border style="width: 100%;">
      <el-table-column prop="id" label="#"></el-table-column>
      <el-table-column prop="title" :label="$t('jj.biaoti')"></el-table-column>
      <el-table-column prop="iscan" :label="$t('jj.sfzdyje')">
        <template slot-scope="{ row }">
          <span v-if="row.iscan === '1'">{{ $t('shi') }}</span>
          <span v-else>{{ $t('shi') }}</span>
        </template>
      </el-table-column>
      <el-table-column prop="times" :label="$t('jj.sjjgwf')"></el-table-column>
      <el-table-column prop="values" :label="$t('jj.jejgwf')"></el-table-column>
      <el-table-column prop="fee" :label="$t('jj.lilv')"></el-table-column>
      <el-table-column prop="total" :label="$t('jj.zongshu')"></el-table-column>
      <el-table-column prop="canuse" :label="$t('jj.keyong')"></el-table-column>
      <el-table-column prop="used" :label="$t('jj.yiyong')"></el-table-column>
      <el-table-column
        prop="freeze"
        :label="$t('jj.dongjie')"
      ></el-table-column>
      <el-table-column prop="status" :label="$t('jj.sfkq')">
        <template slot-scope="{ row }">
          <span v-if="row.status === '1'">{{ $t('shi') }}</span>
          <span v-else>{{ $t('fou') }}</span>
        </template>
      </el-table-column>
      <el-table-column
        prop="product_type"
        :label="$t('jj.cplx')"
      ></el-table-column>
      <el-table-column
        prop="type_name"
        :label="$t('jj.leixing')"
      ></el-table-column>
      <el-table-column :label="$t('caozuo')" min-width="120px">
        <template slot-scope="{ row }">
          <el-tooltip
            :content="$t('bianji')"
            placement="top"
            :enterable="false"
          >
            <i class="iconfont icon-bianji" @click="edit(row.id)"></i>
          </el-tooltip>
          <el-tooltip
            :content="$t('shanchu')"
            placement="top"
            :enterable="false"
          >
            <i class="el-icon-close" @click="del(row.id)"></i>
          </el-tooltip>
        </template>
      </el-table-column>
    </el-table>
    <!-- 分页区域 -->
    <el-pagination
      :hide-on-single-page="total <= 10"
      background
      layout="prev, pager, next, total"
      :page-size="10"
      :total="total"
      :current-page="queryinfo.page"
      @current-change="changepage"
    ></el-pagination>
    <!-- 添加简介弹窗区域 -->
    <el-dialog
      :title="$t('xinzeng')"
      :visible="dialogvisible"
      :before-close="handleClose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="infoform"
        label-width="170px"
        ref="infoform"
        :rules="formrules"
      >
        <el-form-item :label="$t('jj.biaoti')" prop="title">
          <el-input v-model="infoform.title"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jj.neirong')" prop="content">
          <el-input v-model="infoform.content"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jj.sfzdyje')" required>
          <el-radio-group v-model="infoform.iscan">
            <el-radio :label="'1'">{{ $t('shi') }}</el-radio>
            <el-radio :label="'0'">{{ $t('fou') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('jj.sjjgwf')" prop="times">
          <el-input v-model="infoform.times"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jj.jejgwf')" prop="values">
          <el-input v-model="infoform.values"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jj.lilv')" prop="fee">
          <el-input v-model="infoform.fee"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jj.zongshu')" prop="total">
          <el-input v-model="infoform.total"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jj.sfkq')" required>
          <el-radio-group v-model="infoform.status">
            <el-radio :label="'1'">{{ $t('shi') }}</el-radio>
            <el-radio :label="'0'">{{ $t('fou') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('jj.sfyxbdqtyh')" required>
          <el-radio-group v-model="infoform.isbind">
            <el-radio :label="'1'">{{ $t('shi') }}</el-radio>
            <el-radio :label="'0'">{{ $t('fou') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('jj.cplx')" required>
          <el-select v-model="infoform.product_type">
            <el-option label="USDT" value="USDT"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('jj.leixing')" required>
          <el-select v-model="infoform.type_id">
            <el-option
              v-for="item in types"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="submit">{{ $t('tijiao') }}</el-button>
        <el-button @click="handleClose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
    <!-- 编辑简介弹窗区域 -->
    <el-dialog
      title="编辑"
      :visible="editdialogvisible"
      :before-close="editclose"
      :close-on-click-modal="false"
      :close-on-press-escape="false"
    >
      <el-form
        :model="infoform"
        label-width="170px"
        ref="infoform"
        :rules="formrules"
      >
        <el-form-item :label="$t('jj.biaoti')" prop="title">
          <el-input v-model="infoform.title"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jj.neirong')" prop="content">
          <el-input v-model="infoform.content"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jj.sfzdyje')" required>
          <el-radio-group v-model="infoform.iscan">
            <el-radio :label="'1'">{{ $t('shi') }}</el-radio>
            <el-radio :label="'0'">{{ $t('fou') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('jj.sjjgwf')" prop="times">
          <el-input v-model="infoform.times"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jj.jejgwf')" prop="values">
          <el-input v-model="infoform.values"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jj.lilv')" prop="fee">
          <el-input v-model="infoform.fee"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jj.zongshu')" prop="total">
          <el-input v-model="infoform.total"></el-input>
        </el-form-item>
        <el-form-item :label="$t('jj.sfkq')" required>
          <el-radio-group v-model="infoform.status">
            <el-radio :label="'1'">{{ $t('shi') }}</el-radio>
            <el-radio :label="'0'">{{ $t('fou') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('jj.sfyxbdqtyh')" required>
          <el-radio-group v-model="infoform.isbind">
            <el-radio :label="'1'">{{ $t('shi') }}</el-radio>
            <el-radio :label="'0'">{{ $t('fou') }}</el-radio>
          </el-radio-group>
        </el-form-item>
        <el-form-item :label="$t('jj.cplx')" required>
          <el-select v-model="infoform.product_type">
            <el-option label="USDT" value="USDT"> </el-option>
          </el-select>
        </el-form-item>
        <el-form-item :label="$t('jj.leixing')" required>
          <el-select v-model="infoform.type_id">
            <el-option
              v-for="item in types"
              :key="item.id"
              :label="item.name"
              :value="item.id"
            >
            </el-option>
          </el-select>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button type="primary" @click="editsubmit">{{
          $t('tijiao')
        }}</el-button>
        <el-button @click="editclose">{{ $t('quxiao') }}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  data() {
    return {
      // 分页请求数据对象
      queryinfo: {
        query: '',
        page: 1
      },
      infolist: [],
      total: 0,
      loading: true,
      // 所有类型
      types: [],
      // 添加数据对象
      infoform: {
        title: '',
        content: '',
        iscan: '0',
        times: '',
        values: '',
        fee: '',
        total: '',
        status: '0',
        isbind: '0',
        product_type: '',
        type: ''
      },
      dialogvisible: false,
      formrules: {
        title: [{ required: true, message: '该项必填', trigger: 'blur' }],
        content: [{ required: true, message: '该项必填', trigger: 'blur' }],
        times: [{ required: true, message: '该项必填', trigger: 'blur' }],
        values: [{ required: true, message: '该项必填', trigger: 'blur' }],
        fee: [{ required: true, message: '该项必填', trigger: 'blur' }],
        total: [{ equired: true, message: '该项必填', trigger: 'blur' }]
      },
      editdialogvisible: false
    }
  },
  created() {
    this.getlist()
  },
  methods: {
    async getlist() {
      const { data } = await this.$http.get('/admin/fund/infolist', {
        params: this.queryinfo
      })
      this.infolist = data.data.list
      this.loading = false
    },
    // 添加弹窗
    async addinfo() {
      this.dialogvisible = true
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/fund/addinfo')
      if (data) {
        if (data.code === 200) {
          this.types = data.data
        } else {
          this.$message.error(this.$t('shibai'))
        }
      }
      loading.close()
    },
    // 提交添加
    submit() {
      this.$refs.infoform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/fund/addinfo',
          this.infoform
        )
        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.$message.success(this.getlang(data.msg))
            this.handleClose()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 关闭添加弹窗
    handleClose() {
      this.$refs.infoform.resetFields()
      this.dialogvisible = false
    },
    // 分页事件
    changepage(val) {
      this.queryinfo.page = val
      this.getlist()
    },

    // 打开编辑弹窗
    async edit(id) {
      this.editdialogvisible = true
      const loading = this.$loading({
        lock: true,
        spinner: 'el-icon-loading',
        background: 'rgba(255,255, 255, 0.7)',
        target: document.querySelector('.el-dialog__body')
      })
      const { data } = await this.$http.get('/admin/fund/editinfo/id/' + id)
      this.infoform = data.data.info
      this.types = data.data.types
      loading.close()
    },
    // 编辑提交
    editsubmit() {
      this.$refs.infoform.validate(async valid => {
        if (!valid) return false
        const { data } = await this.$http.post(
          '/admin/fund/editinfo',
          this.infoform
        )
        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.$message.success(this.getlang(data.msg))
            this.editclose()
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 关闭编辑弹窗
    editclose() {
      this.$refs.infoform.resetFields()
      this.editdialogvisible = false
    },
    // 删除操作
    del(id) {
      this.$confirm(this.$t('shanchutips'), this.$t('tishi'), {
        confirmButtonText: this.$t('quding'),
        cancelButtonText: this.$t('quxiao'),
        type: 'warning'
      }).then(async () => {
        const { data } = await this.$http.post('/admin/fund/delinfo', {
          id
        })

        if (data) {
          if (data.code === 200) {
            this.getlist()
            this.$message.success(this.getlang(data.msg))
          } else {
            this.$message.error(this.getlang(data.msg))
          }
        }
      })
    },
    // 图片上传成功操作
    upload(res) {
      this.infoform.image = res.data.url
    }
  }
}
</script>

<style lang="less" scoped>
.iconfont,
.el-icon-close {
  font-size: 18px;
  border: 1px solid #ddd;
  border-radius: 4px;
  margin-right: 15px;
  padding: 1px;
  &:hover {
    color: #409eff;
    cursor: pointer;
    border-color: #409eff;
  }
}

.avatar-uploader {
  border: 1px dashed #d9d9d9;
  border-radius: 6px;
  cursor: pointer;
  position: relative;
  overflow: hidden;
  width: 80px;
  height: 80px;
}
.avatar-uploader:hover {
  border-color: #409eff;
}
.avatar-uploader-icon {
  font-size: 28px;
  color: #8c939d;
  width: 80px;
  height: 80px;
  line-height: 80px;
  text-align: center;
}
.avatar {
  width: 80px;
  height: 80px;
  display: block;
}
</style>
